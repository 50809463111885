<template>
  <div
    v-if="this.$route.matched.length > 2"
    class="animated fadeIn"
  >
    <router-view />
  </div>
  <div
    v-else
    class="animated fadeIn"
  >
    <b-card no-body>
      <b-card-header>
        Аукционы
        <div class="card-header-actions">
          <small class="text-muted">Дата последнего обновления {{ lastRefreshTime }} </small>
          <b-button
            :disabled="loading"
            variant="primary"
            size="sm"
            class="ml-1"
            @click="$refs.table.refresh()"
          >
            <i
              :class="{'fa-spin' : loading}"
              class="fa fa-refresh"
            />&nbsp;Обновить
          </b-button>
        </div>
      </b-card-header>
      <b-card-body :class="{'loading' : loading}">
        <v-server-table
          v-if="filter.ready"
          id="dataTable"
          ref="table"
          :columns="columns"
          :options="options"
          :theme="theme"
          @loaded="loading = false"
        >
          <span
            slot="bodyTypes"
            slot-scope="props"
          >
            <span v-if="props.row.bodyTypes.length > 0">
              <span
                v-for="(bodyType, index) in props.row.bodyTypes"
                :key="'bodyType-' + index"
              >
                <span
                  v-if="bodyType.name === 'Тент с усиленной обрешеткой'"
                  v-b-tooltip.hover.bottom="'Тент с усиленной обрешеткой'"
                  class="mr-1"
                >
                  <img
                    src="/img/icons/body-types/wood.png"
                    alt="Тент с усиленной обрешеткой"
                  >
                </span>
                <span
                  v-else-if="bodyType.name === 'Изотермический'"
                  v-b-tooltip.hover.bottom="'Изотермический'"
                  class="mr-1"
                >
                  <img
                    src="/img/icons/body-types/3d.png"
                    alt="Изотермический"
                  >
                </span>
                <span
                  v-else-if="bodyType.name === 'Контейнер'"
                  v-b-tooltip.hover.bottom="'Контейнер'"
                  class="mr-1"
                >
                  <img
                    src="/img/icons/body-types/container.png"
                    alt="Контейнер"
                  >
                </span>
                <span
                  v-else-if="bodyType.name === 'Рефрижератор'"
                  v-b-tooltip.hover.bottom="'Рефрижератор'"
                  class="mr-1"
                >
                  <img
                    src="/img/icons/body-types/temperature-control.png"
                    alt="Рефрижератор"
                  >
                </span>
                <span v-else>{{ bodyType.name }}</span>
              </span>
            </span>
          </span>
          <span
            slot="startPrice"
            slot-scope="props"
          >
            <span v-if="props.row.startPrice">{{ props.row.startPrice }} руб. {{ alternativeAmountValue(props.row.withNDS, props.row.startPrice) }}</span>
            <span v-else>Н/Д</span>
          </span>
          <span
            slot="routes.0.planDateOfFirstPointArrive"
            slot-scope="props"
          ><span v-if="props.row.routes.length > 0">{{ formatDatetimeWithOriginalTimezone(props.row.routes[0].planDateOfFirstPointArrive) }}</span></span>
          <span
            slot="startDate"
            slot-scope="props"
          >{{ formatDatetime(props.row.startDate) }}</span>
          <span
            slot="stopDate"
            slot-scope="props"
          >{{ props.row.factEndDate ? formatDatetime(props.row.factEndDate) : formatDatetime(props.row.stopDate) }}
          </span>
          <span
            slot="stl"
            slot-scope="props"
          >
            <span
              v-for="route in props.row.routes"
              :key="route.id"
            >
              <span v-if="route.naRouteOwner">{{ route.naRouteOwner.name }}</span>
            </span>
          </span>
          <span
            slot="activeBid"
            slot-scope="props"
          >
            <span v-if="props.row.status === 4 || props.row.status === 6">
              <span v-if="props.row.bids.length > 0">
                <span
                  v-for="(bid, index) in props.row.bids"
                  :key="'bid-' + index"
                >
                  <span v-if="bid.winner">({{ bid.contractor.name }}) — {{ bid.bet }} руб. {{ alternativeAmountValue(props.row.withNDS, bid.bet) }}</span>
                </span>
              </span>
              <span v-if="props.row.offers.length > 0">
                <span
                  v-for="(offer, index) in props.row.offers"
                  :key="'offer-' + index"
                >
                  <span v-if="offer.winner">({{ offer.contractor.name }}) — {{ offer.bet }} руб. {{ alternativeAmountValue(props.row.withNDS, offer.bet) }}</span>
                </span>
              </span>
            </span>
            <span v-else>
              <span v-if="props.row.bids.length > 0">
                <span
                  v-for="(bid, index) in props.row.bids"
                  :key="'bid-' + index"
                >
                  <span v-if="bid.active">({{ bid.contractor.name }}) — {{ bid.bet }} руб. {{ alternativeAmountValue(props.row.withNDS, bid.bet) }}</span>
                </span>
              </span>
              <span v-else>Н/Д
                <span
                  v-if="props.row.offers.length > 0"
                  class="text-warning"
                >(Есть встречные)</span>
              </span>
            </span>
          </span>
          <span
            slot="savingOverrun"
            slot-scope="props"
          >
            <span v-if="props.row.bids.length > 0">
              <span
                v-for="(bid, index) in props.row.bids"
                :key="'bid-' + index"
              >
                <span v-if="bid.winner">
                  <span
                    v-if="props.row.startPrice - bid.bet > 0"
                    class="text-success"
                  >{{ props.row.startPrice - bid.bet }} руб. {{ alternativeAmountValue(props.row.withNDS, props.row.startPrice - bid.bet) }}</span>
                  <span
                    v-else-if="props.row.startPrice - bid.bet < 0"
                    class="text-danger"
                  >{{ props.row.startPrice - bid.bet }} руб. {{ alternativeAmountValue(props.row.withNDS, props.row.startPrice - bid.bet) }}</span>
                  <span v-else>{{ props.row.startPrice - bid.bet }} руб. {{ alternativeAmountValue(props.row.withNDS, props.row.startPrice - bid.bet) }}</span>
                </span>
              </span>
            </span>
            <span v-if="props.row.offers.length > 0">
              <span
                v-for="(offer, index) in props.row.offers"
                :key="'offer-' + index"
              >
                <span v-if="offer.winner">
                  <span
                    v-if="props.row.startPrice - offer.bet > 0"
                    class="text-success"
                  >{{ props.row.startPrice - offer.bet }} руб. {{ alternativeAmountValue(props.row.withNDS, props.row.startPrice - offer.bet) }}</span>
                  <span
                    v-else-if="props.row.startPrice - offer.bet < 0"
                    class="text-danger"
                  >{{ props.row.startPrice - offer.bet }} руб. {{ alternativeAmountValue(props.row.withNDS, props.row.startPrice - offer.bet) }}</span>
                  <span v-else>{{ props.row.startPrice - offer.bet }} руб. {{ alternativeAmountValue(props.row.withNDS, props.row.startPrice - offer.bet) }}</span>
                </span>
              </span>
            </span>
          </span>
          <span
            slot="status"
            slot-scope="props"
          >
            <span
              v-if="props.row.status === 1"
              class="text-primary"
            >Ожидает начала</span>
            <span
              v-else-if="props.row.status === 2"
              class="text-success"
            >Прием ставок</span>
            <span
              v-else-if="props.row.status === 3"
              class="text-warning"
            >Нет победителя</span>
            <span
              v-else-if="props.row.status === 4"
              class="text-success"
            >Есть победитель</span>
            <span v-else />
          </span>
          <span
            slot="cancelReason"
            slot-scope="props"
          >
            {{ getCancelReasonText(props.row.cancelReason ) }}
          </span>
          <span
            slot="actions"
            slot-scope="props"
          >
            <a
              v-if="props.row.status === 0 && props.row.routes.length > 0 && canLaunch(props.row)"
              v-b-tooltip.hover.bottom="'Запустить аукцион'"
              class="fa fa-mail-forward fa-lg text-success table-action-button"
              @click="openAuctionStartModal(props.row)"
            />
            <a
              v-if="props.row.status === 1"
              v-b-tooltip.hover.bottom="'Изменить период проведения'"
              class="fa fa-history fa-lg text-warning table-action-button"
              @click="openAuctionStartModal(props.row)"
            />
            <a
              v-if="props.row.status === 3"
              v-b-tooltip.hover.bottom="'Перезапустить аукцион'"
              class="fa fa-repeat fa-lg text-warning table-action-button"
              @click="openAuctionStartModal(props.row)"
            />
            <a
              v-if="props.row.status === 0"
              v-b-tooltip.hover.bottom="'Редактировать'"
              class="fa fa-pencil fa-lg text-primary table-action-button"
              :href="'/auctions/customer/auction/id' + props.row.id"
            />
            <a
              v-if="props.row.status !== 0 && props.row.status !== 5 && props.row.status !== 6"
              v-b-tooltip.hover.bottom="'Вернуть в черновик'"
              class="fa fa-reply fa-lg text-primary table-action-button"
              @click="onDraft(props.row.id)"
            />
            <a
              v-b-tooltip.hover.bottom="'Просмотреть'"
              class="fa fa-eye fa-lg text-secondary table-action-button"
              :href="'/customers/id' + $store.state.user.ownerId + '/auction/id' + props.row.id"
            />
            <a
              v-if="props.row.status !== 5 && props.row.status !== 6"
              v-b-tooltip.hover.bottom="'Удалить аукцион'"
              class="fa fa-close fa-lg text-danger table-action-button"
              @click="confirmDeleteAuction(props.row.id)"
            />
          </span>
        </v-server-table>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import Vue from 'vue';
import {ServerTable, Event} from 'vue-tables-2';
import notifications from '../../components/mixins/notifications';
import {
  customersAuctionsList,
  customersAuctionClearRun,
  customersAuctionChangeDates,
  customersAuctionCancel,
  customersAuctionDraft,
  customersAuctionsRoutesList,
  routesTransportationTypesList,
} from '../../services/api';
import {valueToValueWithVAT, valueToValueWithoutVAT} from '@/components/mixins/helpers';
import moment from 'moment';
import tz from 'moment-timezone';
import {arrayStringToNumber} from '@/components/mixins/helpers';
import {queryToUrl, URLToQuery} from '@/services/http';
import {auctionCancelReason} from '@/components/mixins/auctionCancelReason';

Vue.use(ServerTable);

export default {
  name: 'AuctionsInStatistic',
  components: {
    ServerTable,
    Event,
  },
  mixins: [notifications, auctionCancelReason],
  props: ['date-from', 'date-to'],
  data: function() {
    return {
      lastRefreshTime: null,
      auctionsId: null,
      auctionsStatus: null,
      routeId: null,
      routeCode: null,
      categories: [
        {
          value: 'drafts',
          text: 'Черновики',
        },
        {
          value: 'active',
          text: 'Активные',
        },
        {
          value: 'completed',
          text: 'Завершенные',
        },
        {
          value: 'graduated',
          text: 'Утвержденные',
        },
        {
          value: 'cancelled',
          text: 'Отмененные',
        },
      ],
      routeIds: [],
      types: [],
      contractors: [],
      statuses: [],
      managers: [],
      filter: {
        route_id: [],
        type: null,
        arrival_date_from: this.dateFrom,
        arrival_date_to: this.dateTo,
        start_date_from: null,
        start_date_to: null,
        end_date_from: null,
        end_date_to: null,
        contractor: [],
        status: [5, 6],
        stl_id: [],
        page: 1,
        limit: 100,
        ready: false,
      },
      loading: false,
      columns: [
        'routes.0.routeCode',
        'stl',
        'startPrice',
        'status',
        'activeBid',
        'savingOverrun',
      ],
      options: {
        customFilters: [
          'category',
          'route_id',
          'type',
          'arrival_date_from',
          'arrival_date_to',
          'start_date_from',
          'start_date_to',
          'end_date_from',
          'end_date_to',
          'contractor',
          'status',
          'stl_id',
        ],
        requestFunction: (data) => {
          this.loading = true;
          this.filter.page = data.page;
          this.filter.limit = data.limit;

          this.lastRefreshTime = moment().format('DD.MM.YYYY HH:mm:ss');
          return customersAuctionsList(this.$store.state.user.ownerId, Object.assign(data, this.updateRouteParams()));
        },
        responseAdapter({data}) {
          data.items.forEach((item) => {
            if (item.bids.length > 0) {
              item.bids = item.bids.filter((x) => x.round === item.round);
            }
            if (item.offers.length > 0) {
              item.offers = item.offers.filter((x) => x.round === item.round);
            }
          });
          return {
            data: data.items,
            count: data.totalCount,
          };
        },
        headings: {
          'name': 'Название',
          'routes.0.routeCode': 'Код рейса',
          'routes.0.routeWay.name': 'Маршрут рейса',
          'routes.0.transportation.name': 'Тип перевозки',
          'bodyTypes': 'Разрешенные типы кузова',
          'stl': 'Группа ответственных',
          'startPrice': 'Начальная цена',
          'routes.0.planDateOfFirstPointArrive': 'Плановая дата прибытия',
          'startDate': 'Старт аукциона',
          'stopDate': 'Окончание аукциона',
          'activeBid': 'Перевозчик и актуальная ставка',
          'savingOverrun': 'Экономия/Перерасход',
          'status': 'Статус',
          'cancelReason': 'Причина отмены',
          'actions': '',
        },
        sortable: [],
        filterable: [],
        sortIcon: {
          base: 'fa',
          up: 'fa-sort-asc',
          down: 'fa-sort-desc',
          is: 'fa-sort',
        },
        pagination: {
          chunk: 10,
          edge: true,
          nav: 'fixed',
        },
        perPage: 1000000,
        texts: {
          count: 'Показаны с {from} по {to} из {count} записей|{count} записей|Одна запись',
          first: 'Первая',
          last: 'Последняя',
          filter: 'Фильтр:',
          filterPlaceholder: 'Поисковый запрос',
          limit: 'Записи:',
          page: 'Страница:',
          noResults: 'Нет совпадающих записей',
          filterBy: 'Фильтр по {column}',
          loading: 'Загрузка...',
          defaultOption: 'Выбор {column}',
          columns: 'Столбцы',
        },
      },
      pickerOptions: {
        firstDayOfWeek: 1,
      },
      startAuctionModal: {
        show: false,
        selected: "standartAuction",
        method: {
          options: [
            {value: 0, text: 'Запустить сейчас'},
            {value: 1, text: 'Запустить как отложенный'},
          ],
          value: 0,
        },
        startDate: '',
        stopDate: '',
        offerDate: '',
      },
      useVuex: false,
      theme: 'bootstrap4',
      template: 'default',
    };
  },
  computed: {
    startAuctionDisabled() {
      if (this.startAuctionModal.method.value) {
        return !(this.startAuctionModal.startDate && this.startAuctionModal.stopDate);
      } else {
        return !this.startAuctionModal.stopDate;
      }
    },
    formStrStart() {
      const calculationFormData = {};
      if (this.startAuctionModal.method.value && this.startAuctionModal.startDate) {
        calculationFormData.startDate = moment(this.startAuctionModal.startDate).format();
      }
      if (this.startAuctionModal.stopDate) {
        calculationFormData.stopDate = moment(this.startAuctionModal.stopDate).format();
      }
      if (this.startAuctionModal.selected === "firstBidWin") {
        calculationFormData.firstBidWin = true;
      }
      if (this.startAuctionModal.selected === "enableOffers") {
        calculationFormData.enableOffers = true;
      }
      if (this.startAuctionModal.offerDate) {
        calculationFormData.offerDate = moment(this.startAuctionModal.offerDate).format();
      }
      return calculationFormData;
    },
    formStrSnakeStart() {
      const calculationFormData = {};
      if (this.startAuctionModal.method.value && this.startAuctionModal.startDate) {
        calculationFormData.start_date = moment(this.startAuctionModal.startDate).format();
      }
      if (this.startAuctionModal.stopDate) {
        calculationFormData.stop_date = moment(this.startAuctionModal.stopDate).format();
      }
      if (this.startAuctionModal.selected === "firstBidWin") {
        calculationFormData.first_bid_win = true;
      }
      if (this.startAuctionModal.selected === "enableOffers") {
        calculationFormData.enable_offers = true;
      }
      if (this.startAuctionModal.offerDate) {
        calculationFormData.offer_date = moment(this.startAuctionModal.offerDate).format();
      }
      return calculationFormData;
    },
  },
  watch: {
    dateFrom: function() {
      this.filter.arrival_date_from = this.dateFrom;
      this.updateDateFilter('arrival_date_from');
    },
    dateTo: function() {
      this.filter.arrival_date_to = this.dateTo;
      this.updateDateFilter('arrival_date_to');
    },
  },
  mounted() {
    const queries = this.$router.currentRoute.query;
    /**
     * Если в URL есть параметр 'limit'
     * то перекидываем таблицу на соответствующее
     * параметру значение
     * */
    if (queries.limit) {
      this.$refs.table.setLimit(queries.limit);
    }
    /**
     * Если в URL есть параметр 'page'
     * то перекидываем таблицу на соответствующее
     * параметру значение
     * */
    if (queries.page) {
      this.$refs.table.setPage(queries.page);
    }
    /**
     * Записываем параметр при
     * изменении страницы таблицы
     * и записываем в URL
     * */
    Event.$on('vue-tables.pagination', (data) => {
      this.updateRouteParams();
    });
    Event.$on('vue-tables.limit', (data) => {
      this.filter.page = 1;
      this.filter.limit = data;
      this.updateRouteParams();
    });
  },
  created() {
    this.initRouteParams();
  },
  methods: {
    alternativeAmountValue(vat, value) {
      if (value) {
        if (!vat) {
          return '(' + valueToValueWithVAT(value) + ' руб. С НДС)';
        } else {
          return '(' + valueToValueWithoutVAT(value) + ' руб. Без НДС)';
        }
      } else {
        return '';
      }
    },
    openAuctionStartModal(auction) {
      this.startAuctionModal.show = true;
      this.auctionsId = auction.id;
      this.auctionsStatus = auction.status;
      this.routeId = auction.routes[0].id;
      this.routeCode = auction.routes[0].routeCode;
      if(auction.firstBidWin) {
          this.startAuctionModal.selected === "firstBidWin";
        } else if (auction.enableOffers) {
          this.startAuctionModal.selected === "enableOffers";
        } else {
          this.startAuctionModal.selected === "standartAuction";
        }
      this.startAuctionModal.offerDate = moment(auction.offerDate).format();
    },
    async customersAuctionsRoutesList(name) {
      this.loading = true;
      const params = {limit: 100};
      if (this.filter.category === 'drafts') {
        [0].map((item, index) => {
          params['status[' + index + ']'] = item;
        });
      } else if (this.filter.category === 'active') {
        [1, 2].map((item, index) => {
          params['status[' + index + ']'] = item;
        });
      } else if (this.filter.category === 'completed') {
        [3, 4].map((item, index) => {
          params['status[' + index + ']'] = item;
        });
      } else if (this.filter.category === 'graduated') {
        [6].map((item, index) => {
          params['status[' + index + ']'] = item;
        });
      } else if (this.filter.category === 'cancelled') {
        [5].map((item, index) => {
          params['status[' + index + ']'] = item;
        });
      }
      if (name) {
        params.query = name;
      }
      const response = await customersAuctionsRoutesList(this.$store.state.user.ownerId, params);
      if (response && response.status === 200) {
        this.routeIds = response.data.items.map((item) => {
          return {value: item.id, text: item.routeCode};
        });
      }
      if (this.filter.route_id.length > 0) {
        this.customersSelectedRoutesList();
      }
      this.loading = false;
    },
    async customersSelectedRoutesList() {
      this.loading = true;
      const selectedRouteWays = {};
      this.filter.route_id.map((item, index) => {
        selectedRouteWays['id[' + index + ']'] = item;
      });
      const response = await customersAuctionsRoutesList(this.$store.state.user.ownerId, selectedRouteWays);
      if (response && response.status === 200) {
        response.data.items.forEach((item) => {
          this.routeIds = this.routeIds.filter((x) => x.value !== item.id);
          this.routeIds.unshift({value: item.id, text: item.routeCode});
        });
      }
      this.loading = false;
    },
    async routesTransportationTypesList() {
      this.loading = true;
      const response = await routesTransportationTypesList({limit: 100});
      if (response && response.status === 200) {
        this.types = response.data.items.map((item) => {
          return {value: item.id, text: item.name, color: item.color, description: item.description};
        });
      }
      this.loading = false;
    },
    async onDraft(auctionsId) {
      this.loading = true;
      const response = await customersAuctionDraft(this.$store.state.user.ownerId, auctionsId);
      if (response && response.status === 200) {
        this.showSuccess('Аукцион переведен в черновик');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    confirmDeleteAuction(auctionsId) {
      this.$bvModal
        .msgBoxConfirm('Вы уверены что хотите удалить аукцион?', {
          okTitle: 'Да',
          cancelTitle: 'Назад',
        })
        .then((confirm) => {
          if (confirm) {
            this.deleteAuction(auctionsId);
          }
        });
    },
    async deleteAuction(auctionsId) {
      this.loading = true;
      const response = await customersAuctionCancel(this.$store.state.user.ownerId, auctionsId);
      if (response && response.status === 200) {
        this.showSuccess('Аукцион удален');
        this.$refs.table.refresh();
      }
      this.loading = false;
    },
    formatDatetimeWithOriginalTimezone(date) {
      const clientTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      return date ? moment(date, 'YYYY-MM-DDTHH:mm').tz(clientTimezone).format('DD.MM.YYYY HH:mm') : '';
    },
    formatDatetime(date) {
      return date ? moment(date).format('DD.MM.YYYY HH:mm') : '';
    },
    updateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] !== '' ? this.filter[field] : null;

        this.updateRouteParams();
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    updateDateFilter(field) {
      if (this.timer) {
        clearTimeout(this.timer);
        this.timer = null;
      }
      this.timer = setTimeout(() => {
        const val = this.filter[field] ? moment(this.filter[field]).format('YYYY-MM-DDTHH:mm') : null;
        this.updateRouteParams();
        Event.$emit('vue-tables.filter::' + field, val);
      }, 800);
    },
    updateRouteParams() {
      const res = {};
      if (this.filter.category) {
        res.category = this.filter.category;
      }
      if (this.filter.route_id.length) {
        res.route_id = this.filter.route_id;
      }
      if (this.filter.stl_id.length) {
        res.stl_id = this.filter.stl_id;
      }
      if (this.filter.arrival_date_from) {
        res.arrival_date_from = moment(this.filter.arrival_date_from).format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.arrival_date_to) {
        res.arrival_date_to = moment(this.filter.arrival_date_to).format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.start_date_from) {
        res.start_date_from = moment(this.filter.start_date_from).utc().format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.start_date_to) {
        res.start_date_to = moment(this.filter.start_date_to).utc().format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.end_date_from) {
        res.end_date_from = moment(this.filter.end_date_from).utc().format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.end_date_to) {
        res.end_date_to = moment(this.filter.end_date_to).utc().format('YYYY-MM-DDTHH:mm');
      }
      if (this.filter.contractor.length) {
        res.contractor = this.filter.contractor;
      }
      if (this.filter.status.length) {
        res.status = this.filter.status;
      } else {
        if (this.filter.category === 'drafts') {
          res.status = [0];
        } else if (this.filter.category === 'active') {
          res.status = [1, 2];
        } else if (this.filter.category === 'completed') {
          res.status = [3, 4];
        } else if (this.filter.category === 'graduated') {
          res.status = [6];
        } else if (this.filter.category === 'cancelled') {
          res.status = [5];
        }
        res.category = this.filter.category;
      }
      this.$store.commit('setFilterParams', {componentName: this.$options.name, params: {...this.filter}});
      if (this.filter.page && this.filter.page !== 1) {
        res.page = this.filter.page;
      } else {
        delete res.page;
      }
      history.pushState({}, document.title, this.$router.currentRoute.path);
      if (Object.keys(res).length !== 0) {
        const url = queryToUrl('', res);
        history.pushState(null, null, url);
      }
      return res;
    },
    /**
     * Запись параметров из URL в this.filter
     * */
    initRouteParams() {
      const params = URLToQuery(this.$route.fullPath);
      if (Object.keys(params).length !== 0) {
        if (params.hasOwnProperty('route_id')) {
          params.route_id = arrayStringToNumber(params.route_id);
        }
        if (params.hasOwnProperty('stl_id')) {
          params.route_id = arrayStringToNumber(params.stl_id);
        }
        if (params.hasOwnProperty('status')) {
          if ((params.status.indexOf('1') !== -1 ^ params.status.indexOf('2') !== -1) || (params.status.indexOf('3') !== -1 ^ params.status.indexOf('4') !== -1)) {
            params.status = arrayStringToNumber(params.status);
          } else {
            delete params.status;
          }
        }

        if (params.hasOwnProperty('contractor')) {
          params.contractor = arrayStringToNumber(params.contractor);
        }
        if (params.hasOwnProperty('page')) {
          params.page = Number(params.page);
        }
        this.filter.ready = false;
        this.$nextTick(()=> {
          Object.assign(this.filter, params);
          this.filter.ready = true;
        });
      } else {
        const params = this.$store.getters.getFilterParams(this.$options.name);
        this.filter.ready = false;
        if (params) {
          this.$nextTick(()=> {
            Object.assign(this.filter, params);
            this.filter.ready = true;
          });
        } else {
          this.filter.ready = true;
        }
      }
    },
    async onStart() {
      this.loading = true;
      if (this.auctionsStatus === 0) {
        const response = await customersAuctionClearRun(this.$store.state.user.ownerId, this.auctionsId, this.formStrSnakeStart);
        if (response && response.status === 200) {
          this.showSuccess('Аукцион подготовлен к запуску');
          this.$refs.table.refresh();
        }
      } else if (this.auctionsStatus === 1) {
        const response = await customersAuctionChangeDates(this.$store.state.user.ownerId, this.auctionsId, this.formStrSnakeStart);
        if (response && response.status === 200) {
          this.showSuccess('Даты запуска аукциона успешно обновлены');
          this.$refs.table.refresh();
        }
      } else if (this.auctionsStatus === 3) {
        const response = await customersAuctionDraft(this.$store.state.user.ownerId, this.auctionsId);
        if (response && response.status === 200) {
          this.loading = true;
          const response = await customersAuctionClearRun(this.$store.state.user.ownerId, this.auctionsId, this.formStrSnakeStart);
          if (response && response.status === 200) {
            this.showSuccess('Аукцион подготовлен к запуску');
            this.$refs.table.refresh();
          }
          this.loading = false;
        }
      }
      this.loading = false;
    },
    canLaunch(auction) {
      return !!auction.possibleParticipants.length &&
            !!auction.bodyTypes.length &&
            !!auction.loadingTypes.length &&
            !!auction.dimension &&
            !!auction.startPrice &&
            !!auction.step &&
            auction.routes.reduce((ac, route)=> {
              return !!route.planDateOfFirstPointArrive && !!route.planDateOfFirstPointLoading && ac;
            }
            , true)
      ;
    },
  },
};
</script>

<style lang="scss">

  .auction-filter-tabs {
    margin: -1px;

    &__item {
    }
    &__input {
      display: none;

      &:checked {

        ~ .auction-filter-tabs__display {
          color: #23282c;
          background-color: #fff;
          border-color: #c8ced3 #c8ced3 #fff;
        }
      }
    }
    &__display {
      background-color: transparent;
      margin-bottom: 0;
    }
  }
</style>
